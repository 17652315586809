@tailwind base;
@tailwind components;
@tailwind utilities;


body {
  font-family: 'Inter', sans-serif;
  background-color: #f5f5f5;
  color: #333;
  margin: 0;
  padding: 0;
  line-height: 1.6;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
}

h2 {
  color: #4a4a4a;
}
